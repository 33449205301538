export const ParsleyTheme = [
  {
    token: "constant.character.escape",
    foreground: "00FFFF",
    fontStyle: "italic underline",
  },
  {
    token: "meta.tag",
    foreground: "00FF00",
    fontStyle: "italic underline",
  },
];
