export type Currency = {
  symbol: string;
  label: string;
  symbol_native: string;
  decimal_digits: number;
  rounding: number;
  value: string;
  name_plural: string;
  countryCode: string;
};

export const currencies: Currency[] = [
  {
    symbol: "Af ",
    label: "Afghan Afghani",
    symbol_native: "؋",
    decimal_digits: 0,
    rounding: 0,
    value: "AFN",
    name_plural: "Afghan Afghanis",
    countryCode: "AF",
  },
  {
    symbol: "ALL",
    label: "Albanian Lek",
    symbol_native: "Lek",
    decimal_digits: 0,
    rounding: 0,
    value: "ALL",
    name_plural: "Albanian lekë",
    countryCode: "AL",
  },
  {
    symbol: "DA",
    label: "Algerian Dinar",
    symbol_native: "د.ج.‏",
    decimal_digits: 2,
    rounding: 0,
    value: "DZD",
    name_plural: "Algerian dinars",
    countryCode: "DZ",
  },
  {
    symbol: "AR$",
    label: "Argentine Peso",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    value: "ARS",
    name_plural: "Argentine pesos",
    countryCode: "AR",
  },
  {
    symbol: "AMD",
    label: "Armenian Dram",
    symbol_native: "դր.",
    decimal_digits: 0,
    rounding: 0,
    value: "AMD",
    name_plural: "Armenian drams",
    countryCode: "AM",
  },
  {
    symbol: "AU$",
    label: "Australian Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    value: "AUD",
    name_plural: "Australian dollars",
    countryCode: "AU",
  },
  {
    symbol: "man.",
    label: "Azerbaijani Manat",
    symbol_native: "ман.",
    decimal_digits: 2,
    rounding: 0,
    value: "AZN",
    name_plural: "Azerbaijani manats",
    countryCode: "AZ",
  },
  {
    symbol: "BD",
    label: "Bahraini Dinar",
    symbol_native: "د.ب.‏",
    decimal_digits: 3,
    rounding: 0,
    value: "BHD",
    name_plural: "Bahraini dinars",
    countryCode: "BH",
  },
  {
    symbol: "Tk",
    label: "Bangladeshi Taka",
    symbol_native: "৳",
    decimal_digits: 2,
    rounding: 0,
    value: "BDT",
    name_plural: "Bangladeshi takas",
    countryCode: "BD",
  },
  {
    symbol: "BYR",
    label: "Belarusian Ruble",
    symbol_native: "BYR",
    decimal_digits: 0,
    rounding: 0,
    value: "BYR",
    name_plural: "Belarusian rubles",
    countryCode: "BY",
  },
  {
    symbol: "BZ$",
    label: "Belize Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    value: "BZD",
    name_plural: "Belize dollars",
    countryCode: "BZ",
  },
  {
    symbol: "Bs",
    label: "Bolivian Boliviano",
    symbol_native: "Bs",
    decimal_digits: 2,
    rounding: 0,
    value: "BOB",
    name_plural: "Bolivian bolivianos",
    countryCode: "BO",
  },
  {
    symbol: "KM",
    label: "Bosnia-Herzegovina Convertible Mark",
    symbol_native: "KM",
    decimal_digits: 2,
    rounding: 0,
    value: "BAM",
    name_plural: "Bosnia-Herzegovina convertible marks",
    countryCode: "BA",
  },
  {
    symbol: "BWP",
    label: "Botswanan Pula",
    symbol_native: "P",
    decimal_digits: 2,
    rounding: 0,
    value: "BWP",
    name_plural: "Botswanan pulas",
    countryCode: "BW",
  },
  {
    symbol: "R$",
    label: "Brazilian Real",
    symbol_native: "R$",
    decimal_digits: 2,
    rounding: 0,
    value: "BRL",
    name_plural: "Brazilian reals",
    countryCode: "BR",
  },
  {
    symbol: "£",
    label: "British Pound Sterling",
    symbol_native: "£",
    decimal_digits: 2,
    rounding: 0,
    value: "GBP",
    name_plural: "British pounds sterling",
    countryCode: "GB",
  },
  {
    symbol: "BN$",
    label: "Brunei Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    value: "BND",
    name_plural: "Brunei dollars",
    countryCode: "BN",
  },
  {
    symbol: "BGN",
    label: "Bulgarian Lev",
    symbol_native: "лв.",
    decimal_digits: 2,
    rounding: 0,
    value: "BGN",
    name_plural: "Bulgarian leva",
    countryCode: "BG",
  },
  {
    symbol: "FBu",
    label: "Burundian Franc",
    symbol_native: "FBu",
    decimal_digits: 0,
    rounding: 0,
    value: "BIF",
    name_plural: "Burundian francs",
    countryCode: "BI",
  },
  {
    symbol: "KHR",
    label: "Cambodian Riel",
    symbol_native: "៛",
    decimal_digits: 2,
    rounding: 0,
    value: "KHR",
    name_plural: "Cambodian riels",
    countryCode: "KH",
  },
  {
    symbol: "CA$ ",
    label: "Canadian Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    value: "CAD",
    name_plural: "Canadian dollars",
    countryCode: "CA",
  },
  {
    symbol: "CV$",
    label: "Cape Verdean Escudo",
    symbol_native: "CV$",
    decimal_digits: 2,
    rounding: 0,
    value: "CVE",
    name_plural: "Cape Verdean escudos",
    countryCode: "CV",
  },
  {
    symbol: "CFA",
    label: "CFA Franc BCEAO",
    symbol_native: "CFA",
    decimal_digits: 0,
    rounding: 0,
    value: "XOF",
    name_plural: "CFA francs BCEAO",
    countryCode: "CI",
  },
  {
    symbol: "FCFA",
    label: "CFA Franc BEAC",
    symbol_native: "FCFA",
    decimal_digits: 0,
    rounding: 0,
    value: "XAF",
    name_plural: "CFA francs BEAC",
    countryCode: "CF",
  },
  {
    symbol: "CL$",
    label: "Chilean Peso",
    symbol_native: "$",
    decimal_digits: 0,
    rounding: 0,
    value: "CLP",
    name_plural: "Chilean pesos",
    countryCode: "CL",
  },
  {
    symbol: "CN¥",
    label: "Chinese Yuan",
    symbol_native: "CN¥",
    decimal_digits: 2,
    rounding: 0,
    value: "CNY",
    name_plural: "Chinese yuan",
    countryCode: "CN",
  },
  {
    symbol: "CO$",
    label: "Colombian Peso",
    symbol_native: "$",
    decimal_digits: 0,
    rounding: 0,
    value: "COP",
    name_plural: "Colombian pesos",
    countryCode: "CO",
  },
  {
    symbol: "CF",
    label: "Comorian Franc",
    symbol_native: "FC",
    decimal_digits: 0,
    rounding: 0,
    value: "KMF",
    name_plural: "Comorian francs",
    countryCode: "KM",
  },
  {
    symbol: "CDF",
    label: "Congolese Franc",
    symbol_native: "FrCD",
    decimal_digits: 2,
    rounding: 0,
    value: "CDF",
    name_plural: "Congolese francs",
    countryCode: "CD",
  },
  {
    symbol: "₡",
    label: "Costa Rican Colón",
    symbol_native: "₡",
    decimal_digits: 0,
    rounding: 0,
    value: "CRC",
    name_plural: "Costa Rican colóns",
    countryCode: "CR",
  },
  {
    symbol: "kn",
    label: "Croatian Kuna",
    symbol_native: "kn",
    decimal_digits: 2,
    rounding: 0,
    value: "HRK",
    name_plural: "Croatian kunas",
    countryCode: "HR",
  },
  {
    symbol: "Kč",
    label: "Czech Republic Koruna",
    symbol_native: "Kč",
    decimal_digits: 2,
    rounding: 0,
    value: "CZK",
    name_plural: "Czech Republic korunas",
    countryCode: "CZ",
  },
  {
    symbol: "Dkr",
    label: "Danish Krone",
    symbol_native: "kr",
    decimal_digits: 2,
    rounding: 0,
    value: "DKK",
    name_plural: "Danish kroner",
    countryCode: "DK",
  },
  {
    symbol: "Fdj",
    label: "Djiboutian Franc",
    symbol_native: "Fdj",
    decimal_digits: 0,
    rounding: 0,
    value: "DJF",
    name_plural: "Djiboutian francs",
    countryCode: "DJ",
  },
  {
    symbol: "RD$",
    label: "Dominican Peso",
    symbol_native: "RD$",
    decimal_digits: 2,
    rounding: 0,
    value: "DOP",
    name_plural: "Dominican pesos",
    countryCode: "DO",
  },
  {
    symbol: "EGP",
    label: "Egyptian Pound",
    symbol_native: "ج.م.‏",
    decimal_digits: 2,
    rounding: 0,
    value: "EGP",
    name_plural: "Egyptian pounds",
    countryCode: "EG",
  },
  {
    symbol: "Nfk",
    label: "Eritrean Nakfa",
    symbol_native: "Nfk",
    decimal_digits: 2,
    rounding: 0,
    value: "ERN",
    name_plural: "Eritrean nakfas",
    countryCode: "ER",
  },
  {
    symbol: "Ekr",
    label: "Estonian Kroon",
    symbol_native: "kr",
    decimal_digits: 2,
    rounding: 0,
    value: "EEK",
    name_plural: "Estonian kroons",
    countryCode: "EE",
  },
  {
    symbol: "Br",
    label: "Ethiopian Birr",
    symbol_native: "Br",
    decimal_digits: 2,
    rounding: 0,
    value: "ETB",
    name_plural: "Ethiopian birrs",
    countryCode: "ET",
  },
  {
    symbol: " € ",
    label: "Euro",
    symbol_native: "€",
    decimal_digits: 2,
    rounding: 0,
    value: "EUR",
    name_plural: "euros",
    countryCode: "EU",
  },
  {
    symbol: "GEL",
    label: "Georgian Lari",
    symbol_native: "GEL",
    decimal_digits: 2,
    rounding: 0,
    value: "GEL",
    name_plural: "Georgian laris",
    countryCode: "GE",
  },
  {
    symbol: "GH₵",
    label: "Ghanaian Cedi",
    symbol_native: "GH₵",
    decimal_digits: 2,
    rounding: 0,
    value: "GHS",
    name_plural: "Ghanaian cedis",
    countryCode: "GH",
  },
  {
    symbol: "GTQ",
    label: "Guatemalan Quetzal",
    symbol_native: "Q",
    decimal_digits: 2,
    rounding: 0,
    value: "GTQ",
    name_plural: "Guatemalan quetzals",
    countryCode: "GT",
  },
  {
    symbol: "FG",
    label: "Guinean Franc",
    symbol_native: "FG",
    decimal_digits: 0,
    rounding: 0,
    value: "GNF",
    name_plural: "Guinean francs",
    countryCode: "GN",
  },
  {
    symbol: "HNL",
    label: "Honduran Lempira",
    symbol_native: "L",
    decimal_digits: 2,
    rounding: 0,
    value: "HNL",
    name_plural: "Honduran lempiras",
    countryCode: "HN",
  },
  {
    symbol: "HK$",
    label: "Hong Kong Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    value: "HKD",
    name_plural: "Hong Kong dollars",
    countryCode: "HK",
  },
  {
    symbol: "Ft",
    label: "Hungarian Forint",
    symbol_native: "Ft",
    decimal_digits: 0,
    rounding: 0,
    value: "HUF",
    name_plural: "Hungarian forints",
    countryCode: "HU",
  },
  {
    symbol: "Ikr",
    label: "Icelandic Króna",
    symbol_native: "kr",
    decimal_digits: 0,
    rounding: 0,
    value: "ISK",
    name_plural: "Icelandic krónur",
    countryCode: "IS",
  },
  {
    symbol: "Rs",
    label: "Indian Rupee",
    symbol_native: "₹",
    decimal_digits: 2,
    rounding: 0,
    value: "INR",
    name_plural: "Indian rupees",
    countryCode: "IN",
  },
  {
    symbol: "Rp",
    label: "Indonesian Rupiah",
    symbol_native: "Rp",
    decimal_digits: 0,
    rounding: 0,
    value: "IDR",
    name_plural: "Indonesian rupiahs",
    countryCode: "ID",
  },
  {
    symbol: "IRR",
    label: "Iranian Rial",
    symbol_native: "﷼",
    decimal_digits: 0,
    rounding: 0,
    value: "IRR",
    name_plural: "Iranian rials",
    countryCode: "IR",
  },
  {
    symbol: "IQD",
    label: "Iraqi Dinar",
    symbol_native: "د.ع.‏",
    decimal_digits: 0,
    rounding: 0,
    value: "IQD",
    name_plural: "Iraqi dinars",
    countryCode: "IQ",
  },
  {
    symbol: "₪",
    label: "Israeli New Sheqel",
    symbol_native: "₪",
    decimal_digits: 2,
    rounding: 0,
    value: "ILS",
    name_plural: "Israeli new sheqels",
    countryCode: "IL",
  },
  {
    symbol: "J$",
    label: "Jamaican Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    value: "JMD",
    name_plural: "Jamaican dollars",
    countryCode: "JM",
  },
  {
    symbol: "¥",
    label: "Japanese Yen",
    symbol_native: "￥",
    decimal_digits: 0,
    rounding: 0,
    value: "JPY",
    name_plural: "Japanese yen",
    countryCode: "JP",
  },
  {
    symbol: "JD",
    label: "Jordanian Dinar",
    symbol_native: "د.أ.‏",
    decimal_digits: 3,
    rounding: 0,
    value: "JOD",
    name_plural: "Jordanian dinars",
    countryCode: "JO",
  },
  {
    symbol: "KZT",
    label: "Kazakhstani Tenge",
    symbol_native: "тңг.",
    decimal_digits: 2,
    rounding: 0,
    value: "KZT",
    name_plural: "Kazakhstani tenges",
    countryCode: "KZ",
  },
  {
    symbol: "Ksh",
    label: "Kenyan Shilling",
    symbol_native: "Ksh",
    decimal_digits: 2,
    rounding: 0,
    value: "KES",
    name_plural: "Kenyan shillings",
    countryCode: "KE",
  },
  {
    symbol: "KD",
    label: "Kuwaiti Dinar",
    symbol_native: "د.ك.‏",
    decimal_digits: 3,
    rounding: 0,
    value: "KWD",
    name_plural: "Kuwaiti dinars",
    countryCode: "KW",
  },
  {
    symbol: "Ls",
    label: "Latvian Lats",
    symbol_native: "Ls",
    decimal_digits: 2,
    rounding: 0,
    value: "LVL",
    name_plural: "Latvian lati",
    countryCode: "LV",
  },
  {
    symbol: "LB£",
    label: "Lebanese Pound",
    symbol_native: "ل.ل.‏",
    decimal_digits: 0,
    rounding: 0,
    value: "LBP",
    name_plural: "Lebanese pounds",
    countryCode: "LB",
  },
  {
    symbol: "LD",
    label: "Libyan Dinar",
    symbol_native: "د.ل.‏",
    decimal_digits: 3,
    rounding: 0,
    value: "LYD",
    name_plural: "Libyan dinars",
    countryCode: "LY",
  },
  {
    symbol: "Lt",
    label: "Lithuanian Litas",
    symbol_native: "Lt",
    decimal_digits: 2,
    rounding: 0,
    value: "LTL",
    name_plural: "Lithuanian litai",
    countryCode: "LT",
  },
  {
    symbol: "MOP$",
    label: "Macanese Pataca",
    symbol_native: "MOP$",
    decimal_digits: 2,
    rounding: 0,
    value: "MOP",
    name_plural: "Macanese patacas",
    countryCode: "MO",
  },
  {
    symbol: "MKD",
    label: "Macedonian Denar",
    symbol_native: "ден",
    decimal_digits: 2,
    rounding: 0,
    value: "MKD",
    name_plural: "Macedonian denari",
    countryCode: "MK",
  },
  {
    symbol: "MGA",
    label: "Malagasy Ariary",
    symbol_native: "MGA",
    decimal_digits: 0,
    rounding: 0,
    value: "MGA",
    name_plural: "Malagasy Ariaries",
    countryCode: "MG",
  },
  {
    symbol: "RM",
    label: "Malaysian Ringgit",
    symbol_native: "RM",
    decimal_digits: 2,
    rounding: 0,
    value: "MYR",
    name_plural: "Malaysian ringgits",
    countryCode: "MY",
  },
  {
    symbol: "MURs",
    label: "Mauritian Rupee",
    symbol_native: "MURs",
    decimal_digits: 0,
    rounding: 0,
    value: "MUR",
    name_plural: "Mauritian rupees",
    countryCode: "MU",
  },
  {
    symbol: "MX$",
    label: "Mexican Peso",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    value: "MXN",
    name_plural: "Mexican pesos",
    countryCode: "MX",
  },
  {
    symbol: "MDL",
    label: "Moldovan Leu",
    symbol_native: "MDL",
    decimal_digits: 2,
    rounding: 0,
    value: "MDL",
    name_plural: "Moldovan lei",
    countryCode: "MD",
  },
  {
    symbol: "MAD",
    label: "Moroccan Dirham",
    symbol_native: "د.م.‏",
    decimal_digits: 2,
    rounding: 0,
    value: "MAD",
    name_plural: "Moroccan dirhams",
    countryCode: "MA",
  },
  {
    symbol: "MTn",
    label: "Mozambican Metical",
    symbol_native: "MTn",
    decimal_digits: 2,
    rounding: 0,
    value: "MZN",
    name_plural: "Mozambican meticals",
    countryCode: "MZ",
  },
  {
    symbol: "MMK",
    label: "Myanma Kyat",
    symbol_native: "K",
    decimal_digits: 0,
    rounding: 0,
    value: "MMK",
    name_plural: "Myanma kyats",
    countryCode: "MM",
  },
  {
    symbol: "N$",
    label: "Namibian Dollar",
    symbol_native: "N$",
    decimal_digits: 2,
    rounding: 0,
    value: "NAD",
    name_plural: "Namibian dollars",
    countryCode: "NA",
  },
  {
    symbol: "NPRs",
    label: "Nepalese Rupee",
    symbol_native: "नेरू",
    decimal_digits: 2,
    rounding: 0,
    value: "NPR",
    name_plural: "Nepalese rupees",
    countryCode: "NP",
  },
  {
    symbol: "NT$",
    label: "New Taiwan Dollar",
    symbol_native: "NT$",
    decimal_digits: 2,
    rounding: 0,
    value: "TWD",
    name_plural: "New Taiwan dollars",
    countryCode: "TW",
  },
  {
    symbol: "NZ$",
    label: "New Zealand Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    value: "NZD",
    name_plural: "New Zealand dollars",
    countryCode: "NZ",
  },
  {
    symbol: "C$",
    label: "Nicaraguan Córdoba",
    symbol_native: "C$",
    decimal_digits: 2,
    rounding: 0,
    value: "NIO",
    name_plural: "Nicaraguan córdobas",
    countryCode: "NI",
  },
  {
    symbol: "₦",
    label: "Nigerian Naira",
    symbol_native: "₦",
    decimal_digits: 2,
    rounding: 0,
    value: "NGN",
    name_plural: "Nigerian nairas",
    countryCode: "NG",
  },
  {
    symbol: "Nkr",
    label: "Norwegian Krone",
    symbol_native: "kr",
    decimal_digits: 2,
    rounding: 0,
    value: "NOK",
    name_plural: "Norwegian kroner",
    countryCode: "NO",
  },
  {
    symbol: "OMR",
    label: "Omani Rial",
    symbol_native: "ر.ع.‏",
    decimal_digits: 3,
    rounding: 0,
    value: "OMR",
    name_plural: "Omani rials",
    countryCode: "OM",
  },
  {
    symbol: "PKRs",
    label: "Pakistani Rupee",
    symbol_native: "₨",
    decimal_digits: 0,
    rounding: 0,
    value: "PKR",
    name_plural: "Pakistani rupees",
    countryCode: "PK",
  },
  {
    symbol: "B/.",
    label: "Panamanian Balboa",
    symbol_native: "B/.",
    decimal_digits: 2,
    rounding: 0,
    value: "PAB",
    name_plural: "Panamanian balboas",
    countryCode: "PA",
  },
  {
    symbol: "₲",
    label: "Paraguayan Guarani",
    symbol_native: "₲",
    decimal_digits: 0,
    rounding: 0,
    value: "PYG",
    name_plural: "Paraguayan guaranis",
    countryCode: "PY",
  },
  {
    symbol: "S/.",
    label: "Peruvian Nuevo Sol",
    symbol_native: "S/.",
    decimal_digits: 2,
    rounding: 0,
    value: "PEN",
    name_plural: "Peruvian nuevos soles",
    countryCode: "PE",
  },
  {
    symbol: "₱",
    label: "Philippine Peso",
    symbol_native: "₱",
    decimal_digits: 2,
    rounding: 0,
    value: "PHP",
    name_plural: "Philippine pesos",
    countryCode: "PH",
  },
  {
    symbol: "zł",
    label: "Polish Zloty",
    symbol_native: "zł",
    decimal_digits: 2,
    rounding: 0,
    value: "PLN",
    name_plural: "Polish zlotys",
    countryCode: "PL",
  },
  {
    symbol: "QR",
    label: "Qatari Rial",
    symbol_native: "ر.ق.‏",
    decimal_digits: 2,
    rounding: 0,
    value: "QAR",
    name_plural: "Qatari rials",
    countryCode: "QA",
  },
  {
    symbol: "RON",
    label: "Romanian Leu",
    symbol_native: "RON",
    decimal_digits: 2,
    rounding: 0,
    value: "RON",
    name_plural: "Romanian lei",
    countryCode: "RO",
  },
  {
    symbol: "RUB",
    label: "Russian Ruble",
    symbol_native: "руб.",
    decimal_digits: 2,
    rounding: 0,
    value: "RUB",
    name_plural: "Russian rubles",
    countryCode: "RU",
  },
  {
    symbol: "RWF",
    label: "Rwandan Franc",
    symbol_native: "FR",
    decimal_digits: 0,
    rounding: 0,
    value: "RWF",
    name_plural: "Rwandan francs",
    countryCode: "RW",
  },
  {
    symbol: "SR",
    label: "Saudi Riyal",
    symbol_native: "ر.س.‏",
    decimal_digits: 2,
    rounding: 0,
    value: "SAR",
    name_plural: "Saudi riyals",
    countryCode: "SA",
  },
  {
    symbol: "din.",
    label: "Serbian Dinar",
    symbol_native: "дин.",
    decimal_digits: 0,
    rounding: 0,
    value: "RSD",
    name_plural: "Serbian dinars",
    countryCode: "RS",
  },
  {
    symbol: "S$",
    label: "Singapore Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    value: "SGD",
    name_plural: "Singapore dollars",
    countryCode: "SG",
  },
  {
    symbol: "Ssh",
    label: "Somali Shilling",
    symbol_native: "Ssh",
    decimal_digits: 0,
    rounding: 0,
    value: "SOS",
    name_plural: "Somali shillings",
    countryCode: "SO",
  },
  {
    symbol: "R",
    label: "South African Rand",
    symbol_native: "R",
    decimal_digits: 2,
    rounding: 0,
    value: "ZAR",
    name_plural: "South African rand",
    countryCode: "ZA",
  },
  {
    symbol: "₩",
    label: "South Korean Won",
    symbol_native: "₩",
    decimal_digits: 0,
    rounding: 0,
    value: "KRW",
    name_plural: "South Korean won",
    countryCode: "KR",
  },
  {
    symbol: "SLRs",
    label: "Sri Lankan Rupee",
    symbol_native: "SL Re",
    decimal_digits: 2,
    rounding: 0,
    value: "LKR",
    name_plural: "Sri Lankan rupees",
    countryCode: "LK",
  },
  {
    symbol: "SDG",
    label: "Sudanese Pound",
    symbol_native: "SDG",
    decimal_digits: 2,
    rounding: 0,
    value: "SDG",
    name_plural: "Sudanese pounds",
    countryCode: "SD",
  },
  {
    symbol: "Skr",
    label: "Swedish Krona",
    symbol_native: "kr",
    decimal_digits: 2,
    rounding: 0,
    value: "SEK",
    name_plural: "Swedish kronor",
    countryCode: "SE",
  },
  {
    symbol: "CHF",
    label: "Swiss Franc",
    symbol_native: "CHF",
    decimal_digits: 2,
    rounding: 0.05,
    value: "CHF",
    name_plural: "Swiss francs",
    countryCode: "CH",
  },
  {
    symbol: "SY£",
    label: "Syrian Pound",
    symbol_native: "ل.س.‏",
    decimal_digits: 0,
    rounding: 0,
    value: "SYP",
    name_plural: "Syrian pounds",
    countryCode: "SY",
  },
  {
    symbol: "TSh",
    label: "Tanzanian Shilling",
    symbol_native: "TSh",
    decimal_digits: 0,
    rounding: 0,
    value: "TZS",
    name_plural: "Tanzanian shillings",
    countryCode: "TZ",
  },
  {
    symbol: "฿",
    label: "Thai Baht",
    symbol_native: "฿",
    decimal_digits: 2,
    rounding: 0,
    value: "THB",
    name_plural: "Thai baht",
    countryCode: "TH",
  },
  {
    symbol: "T$",
    label: "Tongan Paʻanga",
    symbol_native: "T$",
    decimal_digits: 2,
    rounding: 0,
    value: "TOP",
    name_plural: "Tongan paʻanga",
    countryCode: "TO",
  },
  {
    symbol: "TT$",
    label: "Trinidad and Tobago Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    value: "TTD",
    name_plural: "Trinidad and Tobago dollars",
    countryCode: "TT",
  },
  {
    symbol: "DT",
    label: "Tunisian Dinar",
    symbol_native: "د.ت.‏",
    decimal_digits: 3,
    rounding: 0,
    value: "TND",
    name_plural: "Tunisian dinars",
    countryCode: "TN",
  },
  {
    symbol: "TL",
    label: "Turkish Lira",
    symbol_native: "TL",
    decimal_digits: 2,
    rounding: 0,
    value: "TRY",
    name_plural: "Turkish Lira",
    countryCode: "TR",
  },
  {
    symbol: "USh",
    label: "Ugandan Shilling",
    symbol_native: "USh",
    decimal_digits: 0,
    rounding: 0,
    value: "UGX",
    name_plural: "Ugandan shillings",
    countryCode: "UG",
  },
  {
    symbol: "₴",
    label: "Ukrainian Hryvnia",
    symbol_native: "₴",
    decimal_digits: 2,
    rounding: 0,
    value: "UAH",
    name_plural: "Ukrainian hryvnias",
    countryCode: "UA",
  },
  {
    symbol: "AED",
    label: "United Arab Emirates Dirham",
    symbol_native: "د.إ.‏",
    decimal_digits: 2,
    rounding: 0,
    value: "AED",
    name_plural: "UAE dirhams",
    countryCode: "AE",
  },
  {
    symbol: " $ ",
    label: "United States Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    value: "USD",
    name_plural: "US dollars",
    countryCode: "US",
  },
  {
    symbol: "$U",
    label: "Uruguayan Peso",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    value: "UYU",
    name_plural: "Uruguayan pesos",
    countryCode: "UY",
  },
  {
    symbol: "UZS",
    label: "Uzbekistan Som",
    symbol_native: "UZS",
    decimal_digits: 0,
    rounding: 0,
    value: "UZS",
    name_plural: "Uzbekistan som",
    countryCode: "UZ",
  },
  {
    symbol: "Bs.F.",
    label: "Venezuelan Bolívar",
    symbol_native: "Bs.F.",
    decimal_digits: 2,
    rounding: 0,
    value: "VEF",
    name_plural: "Venezuelan bolívars",
    countryCode: "VE",
  },
  {
    symbol: "₫",
    label: "Vietnamese Dong",
    symbol_native: "₫",
    decimal_digits: 0,
    rounding: 0,
    value: "VND",
    name_plural: "Vietnamese dong",
    countryCode: "VN",
  },
  {
    symbol: "YR",
    label: "Yemeni Rial",
    symbol_native: "ر.ي.‏",
    decimal_digits: 0,
    rounding: 0,
    value: "YER",
    name_plural: "Yemeni rials",
    countryCode: "YE",
  },
  {
    symbol: "ZK",
    label: "Zambian Kwacha",
    symbol_native: "ZK",
    decimal_digits: 0,
    rounding: 0,
    value: "ZMK",
    name_plural: "Zambian kwachas",
    countryCode: "ZM",
  },
];
